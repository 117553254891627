"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledChildRow = exports.EstimateBreakdownChild = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styles_1 = require("@mui/material/styles");
const material_1 = require("@mui/material");
const TextHelper_1 = require("../../services/TextHelper");
const InfoPopup_1 = require("../parts/InfoPopup");
const StyledChildRow = (0, styles_1.styled)(material_1.Box)(({ theme }) => ({
    '& p': {
        textAlign: 'left',
    },
    '&.childRowLine': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    '& .childRowLineTitle': {
        color: theme.palette.custom.textLight,
        fontSize: '13px',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    '& .childRowLineValue': {
        color: theme.palette.custom.textMedium,
        fontSize: '13px',
    },
    '&.childRowTotal': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    '& .childRowTotalTitle': {
        color: theme.palette.custom.textMedium,
        fontSize: '13px',
    },
    '& .childRowTotalValue': {
        color: theme.palette.custom.textMedium,
        fontSize: '13px',
        fontWeight: 'bold',
    },
    '& .colLeft': {
        width: '100%',
    },
    '& .colRight1': {
        color: theme.palette.custom.textLight,
    },
    '& .colRight2': {
        width: '112px',
        textAlign: 'right',
    },
    '&>.colRight2>p': {
        textAlign: 'right!important',
    },
    '& .refs': {
        fontSize: '0.8em',
        paddingLeft: '5px',
        marginTop: '-10px',
    },
}));
exports.StyledChildRow = StyledChildRow;
const EstimateBreakdownChild = ({ student, periods, config }) => {
    if (!student.site)
        return null;
    const rounding = config.features.move.setup.rounding;
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { sx: { borderBottom: '1px solid #dcdcdc' }, children: [(0, jsx_runtime_1.jsx)(material_1.Box, { children: (0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: 'subtitle2', sx: { color: 'custom.textMedium', paddingTop: 2, paddingBottom: 1 }, children: [student.heading, " ", student.newStudent && '(new student)'] }) }), student.lines.map((line) => {
                var _a, _b;
                const info = (line.infos || []).length > 0 ? (0, jsx_runtime_1.jsx)(InfoPopup_1.InfoPopup, { popId: line.key, infos: line.infos }) : null;
                return ((0, jsx_runtime_1.jsxs)(StyledChildRow, { className: `childRow${line.type}`, children: [(0, jsx_runtime_1.jsx)("div", { className: "colLeft", children: (0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: 'body2', className: `childRow${line.type}Title`, children: [line.title, line.refs && line.refs.length > 0 && (0, jsx_runtime_1.jsxs)("sup", { className: "refs", children: ["[", line.refs.join(', '), "]"] }), info] }) }), line.type === 'Total' && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [periods.nonYear && ((0, jsx_runtime_1.jsx)("div", { className: "colRight1", children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: 'body2', className: `childRow${line.type}Value`, children: (0, jsx_runtime_1.jsx)("strong", { children: (0, TextHelper_1.formatNumberCurrency)(student.periods[periods.nonYear.key], 'never', config.terms) }) }) })), (0, jsx_runtime_1.jsx)("div", { className: "colRight2", children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: 'body2', className: `childRow${line.type}Value`, children: (0, jsx_runtime_1.jsx)("strong", { children: (0, TextHelper_1.formatNumberCurrency)(student.periods[periods.year.key], (_a = rounding === null || rounding === void 0 ? void 0 : rounding.subTotal) === null || _a === void 0 ? void 0 : _a.displayDecimals) }) }) })] })), line.type !== 'Total' && ((0, jsx_runtime_1.jsx)(material_1.Typography, { variant: 'body2', className: `childRow${line.type}Value`, children: (0, TextHelper_1.formatNumberCurrency)(line.amt, (_b = rounding === null || rounding === void 0 ? void 0 : rounding.lineItems) === null || _b === void 0 ? void 0 : _b.displayDecimals, config.terms) }))] }, line.key));
            })] }));
};
exports.EstimateBreakdownChild = EstimateBreakdownChild;

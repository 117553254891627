// Setup the Field configuration for display and validation based on conditionals
// Returns a copy of the field configs, but sets the `display` and `required` booleans based on conditionals
// =====================================================================================================
// IMPORTANT: This service is replicated in the API and Client. Any changes made MUST be cloned to both
// =====================================================================================================
// Sample valid field configs:
// Simple: {"fieldname": {"display": true, "required": false}}
// Required Conditions based on field value:
//    { "fieldname": { "display": true, "requiredConditions": [{ "field": "contactMethod", "val": email }] } }
// Required Conditions based on complex condition:
//    { "fieldname": { "display": true, "requiredConditions": [{ "cond": "allCurrentStudents", "val": false }] } }
// Display to mirror Required Conditions:
//    { "fieldname": { "displayIfRequired": true, "requiredConditions": [{ "cond": "allNewStudents", "val": false }] } }

/* eslint-disable @typescript-eslint/no-extra-semi */

class ValidationService {
  constructor() {
    this.fieldConfig = false
    this.conditionals = {}
    this.values = {}
  }

  setConfig = (fieldConfig) => {
    this.fieldConfig = fieldConfig
  }

  setConditionals = (values) => {
    this.values = values
    let allNewStudents = true
    let allCurrentStudents = true
    values.students.forEach((student) => {
      if (student.current) {
        if (allNewStudents) allNewStudents = false
      } else {
        if (allCurrentStudents) allCurrentStudents = false
      }
    })

    // TODO Fix this so we're not setting values in a Validator
    // Set the value that isn't being used to an empty string
    // this.values.parent[allCurrentStudents ? 'contactMethod' : 'emailParent'] = ''

    this.conditionals = {
      allNewStudents,
      allCurrentStudents,
      needEmail: allCurrentStudents ? values.parent.emailParent === 'yes' : values.parent.contactMethod === 'email',
    }
  }

  getModifiedFieldConfig = (part, activeStudentIndex = null) => {
    if (!this.fieldConfig) return {}

    const _fieldConfig = {}

    const getTestValue = (test) => {
      if (!test.field) return this.conditionals[test.cond]
      if (!test.field.includes('.')) return this.values.family[test.field] //ensure backwards compatible with no 'part' defined

      const [part, fieldName] = test.field.split('.')
      if (part === 'student') return this.values.students[activeStudentIndex][fieldName]

      return this.values[part][fieldName]
    }

    const checkCondition = (test, value) => {
      if (!test.type || test.type === 'valueEquals') return value === test.val

      if (test.type === 'valueBetween') {
        if (isNaN(value)) return false
        if (isNaN(test.valMin) || isNaN(test.valMax))
          throw new Error(
            'Currently valueBetween only supports values that can be converted to numbers (i.e grades 1 and above)',
          )
        return Number(value) >= test.valMin && Number(value) <= test.valMax
      }

      return false
    }

    const getFieldConditional = (tests = {}) => {
      if (tests.type === 'ifRequired') return false //only for display, and always false given logic below
      if (tests.type === 'always') return true
      if (tests.type === 'never') return false
      if (tests.type !== 'conditional' && tests.type !== 'conditionalOr') return false

      let conditionalResults = []

      for (let i = 0; i < tests.conditions.length; i++) {
        const test = tests.conditions[i]

        let value = getTestValue(test)

        const testResult = test.shouldInvert ? !checkCondition(test, value) : checkCondition(test, value)
        conditionalResults.push(testResult)
      }
      return tests.type === 'conditionalOr' ? conditionalResults.some((i) => i) : conditionalResults.every((i) => i)
    }

    const processFieldConfig = (key, field) => {
      const isRequired = getFieldConditional(field.required)
      const shouldDisplay = isRequired || getFieldConditional(field.display)

      _fieldConfig[key] = {
        ...field,
        required: isRequired,
        display: shouldDisplay,
      }
    }

    Object.keys(this.fieldConfig[part]).forEach((key) => {
      if (key === 'custom') {
        this.fieldConfig[part].custom.forEach(
          (field) => processFieldConfig(field.attribute, { ...field, isCustom: true }), //TODO: once all fields are array, 'isCustom' can be removed
        )
        return
      }

      processFieldConfig(key, this.fieldConfig[part][key])
    })

    return _fieldConfig
  }
}

export { ValidationService }

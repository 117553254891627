import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Field, useFormikContext } from 'formik'
import { useConfig } from '../../hooks/useConfig'
import {
  Box,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Typography,
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { RadioGroup } from 'formik-mui'
import { IconGraduate } from './IconGraduate'
import { InfoPopup } from './InfoPopup'
import { OptionalFields } from './OptionalFields'

const StyledRow = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledError = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.error.main,
  fontSize: '16px',
}))

const StudentForm = ({ student, activeStudentIndex, fieldConfig }) => {
  const activeStudent = student
  const { touched, errors, values, setFieldValue } = useFormikContext()
  const studentYearValue = values.students?.[activeStudentIndex]?.startYear
  const config = useConfig()
  const years = Array.from({ length: config.setup.futureYears }, (v, k) => k + new Date().getFullYear())
  const [site, setSite] = useState({})
  const [grade, setGrade] = useState({})

  const childOrThisChild = useMemo(
    () => activeStudent?.name || `this ${config.terms.child}`,
    [config.terms.child, activeStudent?.name],
  )

  // Support dynamic text in field labels and info popups
  const hydrateLabelText = (text) => {
    return text
      .replace('#childName#', childOrThisChild)
      .replace('#startYear#', activeStudent.startYear)
      .replace('#name#', config.name)
  }

  const yearSelectionText = useMemo(() => {
    if (fieldConfig.student.grade?.label) return hydrateLabelText(fieldConfig.student.grade.label)

    return activeStudent?.current === true
      ? `Which ${config.terms.grade.toLowerCase()} is ${childOrThisChild} currently in?`
      : `When would you like ${childOrThisChild} to start at ${config.name}?`
  }, [childOrThisChild, config.terms.grade, config.name, activeStudent])

  useEffect(() => {
    if (fieldConfig.student.startYear?.forceYear) {
      setFieldValue(
        `students.${activeStudentIndex}.startYear`,
        parseInt(fieldConfig.student.startYear?.forceYear, 10),
        false,
      )
      setFieldValue(`students.${activeStudentIndex}.current`, activeStudent?.currentStudent === 'true', false)
    } else {
      if (activeStudent?.currentStudent === 'true') {
        setFieldValue(`students.${activeStudentIndex}.current`, true, false)
        setFieldValue('parent.contactMethod', 'none', false)
        setFieldValue(`students.${activeStudentIndex}.startYear`, new Date().getFullYear(), false)
      } else {
        setFieldValue(`students.${activeStudentIndex}.current`, false, false)
      }
    }
  }, [activeStudent, setFieldValue, activeStudentIndex, activeStudent])

  // setup site conditions
  useEffect(() => {
    if (!(config.options || {}).sites) return false

    if (!student || !student.site) return false

    const site = config.lookup[student.site]
    setSite(site)
    if (site && student.grade) {
      const grade = site.grades.find((i) => i.grade === student.grade)
      if (!grade?.variants || grade?.variants.length < 1) {
        setFieldValue(`students.${activeStudentIndex}.variant`, '')
      }
      setGrade(grade)
    }
  }, [student, config])

  if (!student) return null

  const touchedContext = (touched.students || [{}])[activeStudentIndex] || {}

  const studentCustomFields = config.fields.student.custom.map((f) => fieldConfig.student[f.attribute])

  return (
    <>
      <StyledRow>
        <FormControl variant="filled">
          <FormLabel>{`Enter your ${config.terms.child.toLowerCase()}'s name to help you better understand the fees`}</FormLabel>
          <Field
            name={`students.${activeStudentIndex}.name`}
            as={TextField}
            placeholder={`${config.terms.child}${fieldConfig.student.studentName.required ? '*' : ''}`}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ paddingX: 1, paddingY: 3 }} position="start">
                  <IconGraduate />
                </InputAdornment>
              ),
            }}
            helperText={`Click to add your ${config.terms.child.toLowerCase()}'s name`}
          />
        </FormControl>
      </StyledRow>
      {fieldConfig.student.currentStudent.display && (
        <StyledRow>
          <FormControl>
            <FormLabel>
              {hydrateLabelText(fieldConfig.student.currentStudent.label)}
              {fieldConfig.student.currentStudent.info && (
                <InfoPopup popId="currentStudent" infos={[hydrateLabelText(fieldConfig.student.currentStudent.info)]} />
              )}
            </FormLabel>
            <Field component={RadioGroup} name={`students.${activeStudentIndex}.currentStudent`} row>
              <FormControlLabel
                value={'true'}
                control={<Radio />}
                meta={{
                  error: !!(touchedContext.currentStudent && errors.currentStudent),
                }}
                label="YES"
              />
              <FormControlLabel
                value={'false'}
                control={<Radio />}
                meta={{
                  error: !!(touchedContext.currentStudent && errors.currentStudent),
                }}
                label="NO"
              />
            </Field>
          </FormControl>
          {touchedContext.currentStudent && errors.currentStudent && (
            <StyledError>* Please select yes or no!</StyledError>
          )}
        </StyledRow>
      )}

      {config.options.sites.length > 1 && (
        <StyledRow>
          <FormControl variant="filled">
            <FormLabel>
              {config.terms.siteText
                ? config.terms.siteText
                : `Please select the ${config.terms.site.toLowerCase()} this ${config.terms.child.toLowerCase()} would attend.`}
            </FormLabel>
            <Box>
              <Field
                name={`students.${activeStudentIndex}.site`}
                disableUnderline={true}
                displayEmpty={true}
                sx={{ width: 200 }}
                renderValue={(value) => (config.lookup[value] || {}).label || `Select ${config.terms.site}`}
                error={!!(touchedContext.site && errors.site)}
                as={Select}
              >
                {config.options.sites.map((site) => (
                  <MenuItem key={site.id} value={site.id}>
                    {site.label}
                  </MenuItem>
                ))}
              </Field>
            </Box>
            {touchedContext.site && errors.site && <StyledError>* Please select {config.terms.site}</StyledError>}
          </FormControl>
        </StyledRow>
      )}

      <StyledRow>
        <FormLabel>
          {yearSelectionText}
          {fieldConfig.student.grade?.info && (
            <InfoPopup popId="studentGrade" infos={[hydrateLabelText(fieldConfig.student.grade?.info)]} />
          )}
        </FormLabel>
        <Box>
          {(!student.grade || site.id) && (
            <Field
              name={`students.${activeStudentIndex}.grade`}
              disableUnderline={true}
              sx={{
                width: 200,
                marginRight: 4,
                marginBottom: 1,
              }}
              variant="filled"
              displayEmpty={true}
              disabled={!site.id}
              renderValue={(value) => {
                const selectedGrade = site?.grades?.find((gradeItem) => gradeItem.grade === value)
                return selectedGrade ? selectedGrade.label : config.terms.grade
              }}
              error={!!(touchedContext.grade && errors.grade) || errors.gradeYear}
              as={Select}
            >
              {site.id &&
                (site.grades || []).map((gradeItem, index) => {
                  const hasAvailability = gradeItem.availableFrom || gradeItem.availableTo
                  let isDisabled = false

                  if (hasAvailability) {
                    isDisabled =
                      (gradeItem.availableFrom && studentYearValue < gradeItem.availableFrom) ||
                      (gradeItem.availableTo && studentYearValue > gradeItem.availableTo)
                  }

                  const availabilityText = (() => {
                    if (gradeItem.availableFrom && gradeItem.availableTo) {
                      return `${gradeItem.availableFrom} to ${gradeItem.availableTo}`
                    }
                    if (gradeItem.availableFrom) {
                      return `from ${gradeItem.availableFrom}`
                    }
                    if (gradeItem.availableTo) {
                      return `until ${gradeItem.availableTo}`
                    }
                    return ''
                  })()

                  const labelWithAvailability =
                    hasAvailability && isDisabled
                      ? `${gradeItem.label} (Available ${availabilityText})`
                      : gradeItem.label

                  return (
                    <MenuItem key={`${gradeItem.grade}-${index}`} value={gradeItem.grade} disabled={isDisabled}>
                      {labelWithAvailability}
                    </MenuItem>
                  )
                })}
            </Field>
          )}
          {!fieldConfig.student.startYear?.forceYear && activeStudent?.current !== true && (
            <Field
              name={`students.${activeStudentIndex}.startYear`}
              variant="filled"
              sx={{ width: 120 }}
              disableUnderline={true}
              displayEmpty={true}
              disabled={!site.id}
              renderValue={(value) => {
                return value || 'Year'
              }}
              error={!!(touchedContext.startYear && errors.startYear)}
              as={Select}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Field>
          )}
        </Box>
        {((touchedContext.grade && errors.grade) || (touchedContext.startYear && errors.startYear)) && (
          <StyledError>{errors.grade}</StyledError>
        )}
      </StyledRow>

      {grade && grade.variants && (
        <StyledRow>
          <FormControl>
            <FormLabel>
              {hydrateLabelText(fieldConfig.student.variant?.label)}
              {fieldConfig.student.variant?.info && (
                <InfoPopup popId="variant" infos={[hydrateLabelText(fieldConfig.student.variant?.info)]} />
              )}
            </FormLabel>
            <Field
              name={`students.${activeStudentIndex}.variant`}
              variant="filled"
              disableUnderline={true}
              displayEmpty={true}
              sx={{ width: 200 }}
              renderValue={(value) => {
                const variant = grade.variants.find((x) => x.variant === value)
                return variant ? variant.label : 'Days'
              }}
              error={!!(touchedContext.variant && errors.variant)}
              as={Select}
            >
              {grade &&
                grade.variants.map((i) => (
                  <MenuItem key={i.variant} value={i.variant}>
                    {i.label}
                  </MenuItem>
                ))}
            </Field>
          </FormControl>
        </StyledRow>
      )}
      {studentCustomFields.length > 0 && (
        <OptionalFields slot="student" activeStudentIndex={activeStudentIndex} dataFields={studentCustomFields} />
      )}

      {activeStudent?.grade && activeStudent?.startYear && activeStudent?.current !== null && (
        <Typography variant={'body1'} sx={{ paddingTop: 1 }}>
          We will estimate fees for the following children. To get a clear picture of the fees for your family, add all
          relevant children, and click next when you are ready to move on.
        </Typography>
      )}
    </>
  )
}

StudentForm.propTypes = {
  student: PropTypes.object,
  activeStudentIndex: PropTypes.number,
  fieldConfig: PropTypes.object,
}

export { StudentForm }

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const initial = {
    currentPage: {
        page: 'splash',
    },
    calcResponse: {
        isLoading: false,
        data: {},
        submissionError: false,
    },
    globalError: { severity: 'info' },
    formData: {},
    students: [],
    activeStudentIndex: 0,
    popupRef: {},
    periods: {
        primary: {},
        secondary: {},
    },
    sidebar: {
        show: false,
        display: ['contact'],
        showFutureYearDisclaimer: false,
        width: 3,
    },
    paymentModal: false,
    infoCloseFnRef: null,
    config: {},
    breakdownData: {},
};
exports.default = initial;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetInfoCloseFnRef = void 0;
const react_1 = require("react");
const immer_1 = require("immer");
const store_1 = require("../store");
const useSetInfoCloseFnRef = () => {
    const setState = (0, store_1.useSetState)();
    const setInfoCloseFnRef = (0, react_1.useCallback)(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (infoCloseFnRef) => {
        setState((obj) => (0, immer_1.produce)(obj, (draft) => {
            draft.infoCloseFnRef = (0, immer_1.castDraft)(infoCloseFnRef);
        }));
    }, [setState]);
    return setInfoCloseFnRef;
};
exports.useSetInfoCloseFnRef = useSetInfoCloseFnRef;

import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  useMediaQuery,
  Box,
  Grid,
  Typography,
  Stack,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSetPopupRef } from '../../hooks/useSetPopupRef'
import { usePaymentModal } from '../../hooks/usePaymentModal'
import { useSetPaymentModal } from '../../hooks/useSetPaymentModal'
import { FontIcon } from '../parts/FontIcon'
import theme from '../../theme'
import MacroRenderer from '../../MacroRenderer'

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: 12,
}))

const StyledContentWrapper = styled(DialogContent)(() => ({
  fontSize: '14px',
}))

const StyledHtmlWrapper = styled('p')(({ theme }) => ({
  fontSize: '14px',
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  '& ul': {
    paddingLeft: '16px',
    '& li': {
      listStyle: 'disc',
    },
  },
}))

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  '&:before': {
    backgroundColor: 'white',
  },
  '&.Mui-expanded': {
    marginBottom: 0,
  },
  '& .MuiGrid-item': {
    marginTop: 0,
    marginBottom: 0,
  },
  '& svg': {
    verticalAlign: 'middle',
    height: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: 'visible', //Purely for the demo box to be displayed
  },
  '& .fine-print': {
    color: theme.palette.custom.textLight,
  },
}))

const BlockText = ({ block }) => {
  return (
    <Box>
      {block.items.map((item, i) => {
        const ChosenComponent = itemTypes[item.type]
        return <ChosenComponent key={i} item={item} />
      })}
    </Box>
  )
}

const LinkWrapper = ({ block, children }) => {
  if (!block.link)
    return (
      <Paper
        sx={{
          paddingTop: 1.5,
          paddingBottom: 1.5,
          paddingLeft: 2,
          paddingRight: 2,
          backgroundColor: block.backgroundColor || theme.palette.custom.surfaceMedium,
          border: 'none',
          borderRadius: 0,
        }}
      >
        {children}
      </Paper>
    )

  return (
    <StyledAccordion expanded={false} sx={{ cursor: 'crosshairs' }}>
      <a href={block.link} target="_blank" rel="noreferrer">
        <AccordionSummary
          sx={{
            backgroundColor: block.backgroundColor || theme.palette.custom.surfaceMedium,
            border: 'none',
            cursor: 'default',
          }}
          aria-controls={block.title}
          expandIcon={<OpenInNewIcon sx={{ color: 'primary.main' }} />}
        >
          {children}
        </AccordionSummary>
      </a>
    </StyledAccordion>
  )
}

const BlockSimple = ({ block }) => {
  const imageColumns = block.logo || block.icon ? 1 : 0

  return (
    <LinkWrapper block={block}>
      <Grid container columnSpacing={2}>
        {imageColumns ? (
          <Grid
            item
            xs={imageColumns}
            sx={{
              marginTop: '-4px',
              marginBottom: '-4px',
              display: { xs: 'none', md: 'flex', height: '48px' },
            }}
          >
            {block.logo ? (
              <img src={block.logo} />
            ) : (
              <FontIcon
                sx={{
                  color: 'custom.text',
                  width: '100%',
                  fontSize: '2.9em',
                }}
                iconName={block.icon}
              />
            )}
          </Grid>
        ) : null}
        <Grid item xs={12} md={12 - imageColumns} sx={{ display: 'flex', alignItems: 'center', height: '48px' }}>
          <Typography component="h2" sx={{ color: 'custom.text', fontSize: '1.1em' }}>
            <MacroRenderer text={block.title} />
          </Typography>
        </Grid>
      </Grid>
    </LinkWrapper>
  )
}

const BlockAccordion = ({ block }) => {
  const imageColumns = block.logo || block.icon ? 1 : 0

  return (
    <StyledAccordion defaultExpanded={block.expand}>
      <AccordionSummary
        sx={{
          backgroundColor: block.backgroundColor || theme.palette.custom.surfaceMedium,
          border: 'none',
        }}
        aria-controls={block.title}
        expandIcon={<ExpandMoreIcon sx={{ color: 'custom.text' }} />}
      >
        <Grid container columnSpacing={2} sx={{ height: '48px' }}>
          {imageColumns ? (
            <Grid
              item
              xs={imageColumns}
              sx={{
                display: { xs: 'none', md: 'flex' },
                height: '48px',
              }}
            >
              {block.logo ? (
                <img src={block.logo} />
              ) : (
                <FontIcon
                  sx={{
                    color: 'custom.text',
                    width: '100%',
                    fontSize: '2.9em',
                  }}
                  iconName={block.icon}
                />
              )}
            </Grid>
          ) : null}
          <Grid item xs={12} md={12 - imageColumns} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component="h2" sx={{ color: 'custom.text', fontSize: '1.1em' }}>
              <MacroRenderer text={block.title} />
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ fontSize: '1em', paddingBottom: 1, paddingTop: 2 }}>
        {block.items.map((item, i) => {
          const ChosenComponent = itemTypes[item.type]
          return <ChosenComponent key={i} item={item} size="small" />
        })}
      </AccordionDetails>
    </StyledAccordion>
  )
}

const blockTypes = {
  text: BlockText,
  simple: BlockSimple,
  accordion: BlockAccordion,
}

const ItemHeading = ({ item }) => {
  return (
    <Typography variant="h6" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
      <MacroRenderer text={item.content} />
    </Typography>
  )
}

const ItemParagraph = ({ item }) => {
  return (
    <p style={{ paddingBottom: '10px' }}>
      <MacroRenderer text={item.content} />
    </p>
  )
}

const ItemHtml = ({ item }) => {
  return (
    <StyledHtmlWrapper style={{ paddingBottom: '10px' }}>
      <MacroRenderer dangerouslySetHtml={true} text={item.content} />
    </StyledHtmlWrapper>
  )
}

const ItemFinePrint = ({ item }) => {
  return (
    <p className="fine-print" style={{ paddingBottom: '10px', fontSize: '0.9em', fontStyle: 'italic' }}>
      <MacroRenderer text={item.content} />
    </p>
  )
}

const ItemButton = ({ item, size = 'medium' }) => {
  const icon = !item.icon ? null : <FontIcon iconName={item.icon} />

  return (
    <div style={{ paddingBottom: '10px' }}>
      <Button size={size} variant="contained" startIcon={icon} href={item.link} target="_blank">
        <MacroRenderer text={item.content} />
      </Button>
    </div>
  )
}

const itemTypes = {
  heading: ItemHeading,
  paragraph: ItemParagraph,
  html: ItemHtml,
  button: ItemButton,
  finePrint: ItemFinePrint,
}

const DemoSelector = ({ paymentOptions, chosen, setPaymentConfig }) => {
  if (!chosen) return null

  const handleDemoSelector = (event) => {
    const chosen = paymentOptions.demos.find((item) => item.name === event.target.value)
    if (!chosen) console.error(`Invalid Option selected ${event.target.value}`)

    setPaymentConfig(chosen)
  }

  return (
    <Paper sx={{ position: 'absolute', top: '-150px', right: '0', padding: 2 }} elevation={3}>
      <Typography variant="h5" color={'primary.main'}>
        Select Demo Payment page style:
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={chosen || ''}
          onChange={handleDemoSelector}
        >
          {paymentOptions.demos.map((option) => {
            return <FormControlLabel key={option.name} value={option.name} control={<Radio />} label={option.label} />
          })}
        </RadioGroup>
      </FormControl>
      <br />
      <Typography variant="caption" color={'custom.textMedium'}>
        Note: This selector is for demo purposes and will not be available to parents
      </Typography>
    </Paper>
  )
}

const PaymentOptions = ({ config }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const setPopupRef = useSetPopupRef()
  const paymentOptions = config.terms.paymentOptions
  const [paymentConfig, setPaymentConfig] = useState(
    paymentOptions.sections ? paymentOptions : ((paymentOptions || {}).demos || [])[0],
  )
  const paymentModal = usePaymentModal()
  const setPaymentModal = useSetPaymentModal()

  const closeBreakdown = () => {
    setPopupRef({})
    setPaymentModal(false)
  }

  return (
    <StyledDialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      open={paymentModal}
      onClose={closeBreakdown}
      aria-labelledby="payment-options-dialog"
    >
      <DialogTitle id="payment-options-dialog" sx={{ padding: '20px 40px' }}>
        {paymentOptions.title}
        <StyledCloseButton aria-label="close" onClick={closeBreakdown}>
          <CloseIcon />
        </StyledCloseButton>
      </DialogTitle>
      <StyledContentWrapper sx={{ padding: '20px 40px' }}>
        <Stack spacing={2} sx={{ marginBottom: '10px' }}>
          {paymentConfig.sections.map((block, i) => {
            const BlockComponent = blockTypes[block.type]
            return <BlockComponent key={i} block={block} />
          })}
        </Stack>
        <Box className="notesWrapper">
          <ul className="notes"></ul>
        </Box>
        <Typography variant="caption" sx={{ color: 'custom.textLight' }}>
          {config.name} | © {new Date().getFullYear()} Feesable
        </Typography>
      </StyledContentWrapper>
      {paymentOptions.demos && !fullScreen && (
        <DemoSelector paymentOptions={paymentOptions} chosen={paymentConfig.name} setPaymentConfig={setPaymentConfig} />
      )}
    </StyledDialog>
  )
}

PaymentOptions.propTypes = {
  yearData: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  config: PropTypes.object,
  periods: PropTypes.object,
}

export { PaymentOptions }

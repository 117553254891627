// This file is used in both the GUI and the Email Service for building notes, breakdowns, etc
// ## Please ensure any changes are copied to both locations (manually at this point)

class DataBuilder {
  constructor(buildFor = 'app') {
    /**
     * Build For 'app' or 'email'
     */
    this.buildFor = buildFor

    /**
     * build a collection of notes to output for the breakdown
     */
    this.notes = []

    /**
     * build a register of notes so we can easily see what's been added and what needs creating
     */
    this.noteRegister = {
      lastRef: 0,
    }
  }

  /**
   * Register a new Note, get a referenceId so they can be displayed at the bottom
   * @param {object} note
   * @param {string} category
   */
  addNote(note, category, append = null) {
    const existing = this.noteRegister[note.ruleId]

    if (existing) {
      if (append) existing.append.push(append)
      return existing.ref
    }

    const updatedNote = { ...note, category }

    if (note.ref === undefined) {
      this.noteRegister.lastRef += 1
      updatedNote.ref = this.noteRegister.lastRef
    }

    if (append) updatedNote.append = [append]

    this.noteRegister[updatedNote.ruleId] = updatedNote
    this.notes.push(updatedNote)

    return updatedNote.ref
  }

  resetNotes() {
    this.notes = []
    this.noteRegister = { lastRef: 0 }
  }

  getSortedNotes() {
    // Sort the notes so that those with a numeric ref come before those with null refs
    const sortedNotes = this.notes.sort((a, b) => {
      // Always send the disclaimer to the end
      if (a.ruleId === 'disclaimer' && b.ruleId !== 'disclaimer') return 1
      if (b.ruleId === 'disclaimer' && a.ruleId !== 'disclaimer') return -1
      // Sort by ref, sending null refs (non-numbered notes) to the end
      const refA = a.ref === null ? Infinity : a.ref
      const refB = b.ref === null ? Infinity : b.ref
      return refA - refB
    })
    return sortedNotes
  }

  /**
   * Pluralise a word based on a template (or simply add 's')
   * @param {int} count how many items are there
   * @param {string} text the text with the pluralisation template
   * e.g text = "Item" will return  "Items" if count > 1
   * text = "Item[|s]" will return  "Items" if count > 1
   * text = "Lev[y|ies]" will return "Levies" if count > 1
   */
  pluralise(count, text) {
    if (text.indexOf('[') === -1)
      // If no options have been provided in the text
      return count > 1 ? `${text}s` : text

    const getPart = count > 1 ? 1 : 0
    return text.replace(/(\[.*\|.*\])/g, (match) => match.replace(/[[\]]/g, '').split('|')[getPart])
  }

  buildBreakdown(estimate, config) {
    const res = { submissionId: estimate.submissionId }
    res.years = estimate.years.map((year) => {
      if (this.buildFor === 'app') this.resetNotes() // email needs all notes together, but app is per year
      return this.buildYearBreakdown(year, config)
    })

    res.flags = {
      hasIndicative: res.years.filter((y) => y.flags.indicative).length > 0,
    }

    res.notes = this.buildFor === 'email' ? this.getSortedNotes() : [] // email needs all notes together, but app is per year
    return res
  }

  buildYearBreakdown(year, config) {
    const lines = { inTotal: [], afterTotal: [] }
    const students = []
    const flags = {
      indicative: year.year > config.fees.feeYear || year.increaseApplied,
      increaseApplied: year.increaseApplied,
    }
    year.students.forEach((student) => {
      ;(student.ruleLog || []).forEach((log) => {
        ;(config.fields.data || []).forEach((field) => {
          if (log.field === field.attribute) {
            flags[field.attribute] = true
          }
        })
      })
    })

    const shouldSeparate = config.setup.breakdown.onceOff === 'separate'

    const addLineItem = (item, title, appendTitle = '', groupSimilar = false) => {
      const params = item.params || {} // ensure we have a params object as it's not required on the rule
      const shouldGroupSimilar = groupSimilar && params.canSummariseBy
      let updatedTitle = title
      const lineType = shouldSeparate && (params || {}).excludeFromTotal ? 'afterTotal' : 'inTotal'
      const noteId = shouldGroupSimilar && params.canSummariseBy ? params.canSummariseBy : item.ruleId
      const refs = item.note ? [this.addNote({ ruleId: noteId, note: item.note }, 'year')] : []
      if (this.buildFor === 'email' && item.info)
        refs.push(
          this.addNote(
            { ruleId: noteId, note: item.info },
            'year',
            appendTitle ? `${appendTitle.trim()} - $${item.amt}` : null,
          ),
        )

      if (shouldGroupSimilar) {
        const match = lines[lineType].findIndex((i) => i.canSummariseBy === params.canSummariseBy)
        if (match !== -1) {
          const updated = lines[lineType][match]
          updated.title = this.pluralise(2, item.title)
          updated.info = `${updated.info}\n - ${appendTitle.trim()} - $${item.amt}`
          updated.amt += item.amt
          return
        }
      } else if (appendTitle) updatedTitle = `${this.pluralise(1, updatedTitle)} (${appendTitle})`

      const getInfo = () => {
        if (!shouldGroupSimilar) return item.info || null

        return item.info
          ? `${item.info}\n\nApplies to:\n - ${appendTitle.trim()} - $${item.amt}`
          : `Applies to:\n - ${appendTitle.trim()} - $${item.amt}`
      }

      lines[lineType].push({
        key: item.ruleId,
        type: 'Line',
        title: this.pluralise(1, updatedTitle),
        amt: item.amt,
        info: getInfo(),
        refs,
        flags,
        canSummariseBy: params.canSummariseBy,
      })
    }

    const processFamily = (family) => {
      family.fees.notes.forEach((note) => this.addNote({ ref: null, ...note }, 'family'))
      family.fees.lineItems.forEach((item) => addLineItem(item, item.title))
    }

    // build out Students and related notes
    const processStudent = (student, index) => {
      student.fees.notes.forEach((note) => this.addNote({ ...note, ref: '' }, 'student'))
      const { fees, ...modStudent } = student
      const studentLines = []
      const studentFlags = {
        hasDiscount: fees.subTotals.discount < 0,
      }
      const lineItems = fees.lineItems.map((line) => ({
        ...line,
        title: line.count > 1 ? `${line.title} (Qty ${line.count})` : line.title,
      }))
      let lineItemsCopy = lineItems

      // Compile heading
      const site = config.options.sites.find((s) => s.id === modStudent.site)
      const grade = site.grades.find((g) => g.grade === modStudent.grade)
      const variant = modStudent.variant
        ? ` - ${(grade.variants.find((v) => v.variant === modStudent.variant) || {}).label}`
        : ''
      modStudent.heading = `${modStudent.name} - ${grade.label}${variant}${
        modStudent.site === 'default' ? '' : `, ${site.label}`
      }`

      // Add Separate (excluded) Student fees to bottom for year
      if (shouldSeparate) {
        lineItemsCopy = []
        lineItems.forEach((item) => {
          if (!item.params || !item.params.excludeFromTotal) {
            lineItemsCopy.push(item)
          } else {
            addLineItem(
              item,
              item.title,
              modStudent.heading,
              config.setup.breakdown.additionalItemsGrouping === 'groupSimilar',
            )
          }
        })
      }

      switch (config.setup.breakdown.lineGrouping) {
        case 'none': {
          lineItemsCopy.forEach((item, studentLineIndex) => {
            const refs = item.note ? [this.addNote({ ruleId: item.ruleId, note: item.note }, 'year')] : []
            if (this.buildFor === 'email' && item.info) {
              const noteRuleId = (item.params || {}).canSummariseBy || item.ruleId
              refs.push(this.addNote({ ruleId: noteRuleId, note: item.info }, 'year'))
            }

            studentLines.push({
              key: `${studentLineIndex}-${item.ruleId}`,
              type: 'Line',
              title: item.title,
              amt: item.amt,
              infos: item.info ? [item.info] : [],
              refs,
              count: item.count,
              value: item.value,
            })
          })
          if (fees.subTotals.total !== fees.subTotals.tuition) {
            studentLines.push({
              key: 'total',
              type: 'Total',
              title: config.options.totals.find((x) => x.id === 'total').label,
              amt: fees.subTotals.total,
            })
          } else {
            // If there's only one line, then make it bold as we're not showing the total
            studentLines[studentLines.length - 1].type = 'Total'
          }
          break
        }
        case 'standard': {
          const details = lineItemsCopy.reduce((acc, i) => {
            if (!acc[i.type]) acc[i.type] = { refs: [], infos: [] }
            if (i.note) acc[i.type].refs.push(this.addNote({ ruleId: i.ruleId, note: i.note }, 'studentLines'))
            if (i.info) {
              if (this.buildFor === 'email') {
                const noteRuleId = (i.params || {}).canSummariseBy || i.ruleId
                acc[i.type].refs.push(this.addNote({ ruleId: noteRuleId, note: i.info }, 'studentLines'))
              } else {
                acc[i.type].infos.push(i.info)
              }
            }
            return acc
          }, {})

          ;['tuition', 'fee', 'discount', 'extra'].forEach((key) => {
            if (fees.subTotals[key] !== 0) {
              studentLines.push({
                key: `student_${index}_${key}`,
                type: 'Line',
                title: config.options.totals.find((x) => x.id === key).label,
                amt: fees.subTotals[key],
                refs: details[key].refs || [],
                infos: details[key].infos || [],
              })
            }
          })
          if (fees.subTotals.total !== fees.subTotals.tuition) {
            studentLines.push({
              key: 'total',
              type: 'Total',
              title: config.options.totals.find((x) => x.id === 'total').label,
              amt: fees.subTotals.total,
            })
          } else {
            // If there's only one line, then make it bold as we're not showing the total
            studentLines[studentLines.length - 1].type = 'Total'
          }
          break
        }
        case 'custom':
          fees.breakdown.forEach((bd) => {
            studentLines.push({
              key: bd.id,
              type: 'Line',
              title: bd.label,
              amt: bd.total,
            })
          })
          if (studentLines.length > 1) {
            studentLines.push({
              key: 'total',
              type: 'Total',
              title: config.options.totals.find((x) => x.id === 'total').label,
              amt: fees.subTotals.total,
            })
          } else {
            // If there's only one line, then make it bold as we're not showing the total
            studentLines[studentLines.length - 1].type = 'Total'
          }
          break
        default:
      }
      students.push({
        ...modStudent,
        lines: studentLines,
        subTotal: fees.subTotals.total,
        flags: studentFlags,
        periods: fees.periods,
      })
    }

    year.students.forEach((student, index) => processStudent(student, index))
    processFamily(year.family)
    if (flags.indicative)
      this.addNote({
        ruleId: 'indicative',
        note: config.terms.futureYearsDisclaimer,
        ref: '^',
      })
    if (config.terms.disclaimer)
      this.addNote({
        ruleId: 'disclaimer',
        note: config.terms.disclaimer,
        ref: '',
      })

    return {
      year: year.year,
      totals: year.family.fees.totals,
      periods: year.family.fees.periods,
      instalmentOptions: year.family.fees.instalments,
      lines,
      students,
      notes: this.buildFor === 'app' ? this.getSortedNotes() : [], // email needs all notes together, but app is per year,
      flags,
    }
  }
}

export { DataBuilder }

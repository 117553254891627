"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetBreakdownData = void 0;
const react_1 = require("react");
const immer_1 = require("immer");
const store_1 = require("../store");
const useSetBreakdownData = () => {
    const setState = (0, store_1.useSetState)();
    const setBreakdownData = (0, react_1.useCallback)((breakdownData) => {
        setState((obj) => (0, immer_1.produce)(obj, (draft) => {
            draft.breakdownData = (0, immer_1.castDraft)(breakdownData);
        }));
    }, [setState]);
    return setBreakdownData;
};
exports.useSetBreakdownData = useSetBreakdownData;
